// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

* {
    box-sizing: border-box;
  }
  body {
    background: rgb(236, 229, 229) ;
    background-size: cover;
   
  }

  .sidebar .nav-dropdown.open > .nav-dropdown-items {
    margin-left: 20px;
}

.sidebar-fixed .sidebar {
	position: fixed;
	z-index: 1019;
	width: 210px;
	height: 100vh;
}

.sidebar .sidebar-nav {
	position: relative;
	flex: 1 1;
	width: 300px;
}

.sidebar .nav {
	width: 210px;
	flex-direction: column;
	min-height: 100%;
	padding: 0;
}


